import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCBNns-enGM5KEW8VjNR-jiaoOfPQ9NEz4",
  authDomain: "pomodoros-6daa2.firebaseapp.com",
  projectId: "pomodoros-6daa2",
  storageBucket: "pomodoros-6daa2.appspot.com",
  messagingSenderId: "1004258540686",
  appId: "1:1004258540686:web:d174d9aff5ddd484f55ddf"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export default app;